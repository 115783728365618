import ApiService from "@/common/api/api.service";
import helpers from "@/common/utils/helpers";
// import cloneDeep from "lodash.clonedeep";

const CheckoutService = {
  salvar: function (form) {
    // let formSalvar = cloneDeep(form);
    // formSalvar.valor = helpers.removerValor(formSalvar.valor);
    // formSalvar.valorTotal = helpers.removerValor(formSalvar.valorTotal);
    // formSalvar.numeroDoCartao = formSalvar.numeroDoCartao.replaceAll(" ", "");
    // formSalvar.endereco.cep = formSalvar.endereco.cep.replace("-", "");
    return ApiService.post("pagamento/efetuar-white-label", form);
  },
  buscar: async function (id) {
    let { data } = await ApiService.get(
      `pagamento/obter-dados-intencao-white-label?id=${id}`
    );
    data.data.valor = helpers.formatarValor(data.data.valor);
    return data;
  },
  listar: async function (filtro, NumeroDaPagina = 0, TamanhoDaPagina = 10) {
    return await ApiService.query(
      `pagamento/obter-transacoes?NumeroDaPagina=${NumeroDaPagina}&TamanhoDaPagina=${TamanhoDaPagina}`,
      {
        params: {
          TermoPesquisa: filtro.termo ? filtro.termo : null,
          dataInicio: filtro.dataInicio ? filtro.dataInicio : null,
          dataTermino: filtro.dataFim ? filtro.dataFim : null,
          estabelecimentoId: filtro.estabelecimentoId ? filtro.estabelecimentoId : null,
          efetivadas: filtro.efetivadas ? filtro.efetivadas : false,
          tipo: filtro.tipo ? filtro.tipo : null,
          statusWebHook: filtro.status ? filtro.status : null,
          adquirente: filtro.adquirente ? filtro.adquirente : null,
          plataforma: filtro.plataforma ? filtro.plataforma : null
        },
      }
    );
  },
  relatorios: async function (filtro) {
    return await ApiService.query(
      `pagamento/obter-dados-liquidacao-diaria?NumeroDaPagina=0&TamanhoDaPagina=100`,
      {
        params: {
          dataInicio: filtro.dataInicio ? filtro.dataInicio : null,
          dataFim: filtro.dataFim ? filtro.dataFim : null,
          estabelecimentoId: filtro.estabelecimentoId ? filtro.estabelecimentoId : null,
          naoRav: filtro.naoRav ? filtro.naoRav : false
        },
      }
    );
  },
  aguardarConfirmacao: async function (id) {
    let { data } = await ApiService.get(
      `pagamento/obter-dados-aguardando-confirmacao?id=${id}`
    );
    data.data.valor = helpers.formatarValor(data.data.valor);

    return data;
  },
  obterStatus: async function (id) {
    let { data } = await ApiService.get(
      `pagamento/obter-status-pagamento-finalizado?id=${id}`
    );

    return data.data.status;
  },
  pagamentoNegado: async function (id) {
    let { data } = await ApiService.get(
      `pagamento/obter-dados-pagamento-negado?id=${id}`
    );
    data.data.valor = helpers.formatarValor(data.data.valor);

    return data;
  },
};

export default CheckoutService;
